exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-launch-tsx": () => import("./../../../src/pages/app-launch.tsx" /* webpackChunkName: "component---src-pages-app-launch-tsx" */),
  "component---src-pages-career-advice-id-tsx": () => import("./../../../src/pages/career-advice/ID.tsx" /* webpackChunkName: "component---src-pages-career-advice-id-tsx" */),
  "component---src-pages-career-advice-index-tsx": () => import("./../../../src/pages/career-advice/index.tsx" /* webpackChunkName: "component---src-pages-career-advice-index-tsx" */),
  "component---src-pages-compliance-tsx": () => import("./../../../src/pages/compliance.tsx" /* webpackChunkName: "component---src-pages-compliance-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-employer-about-us-tsx": () => import("./../../../src/pages/employer/about-us.tsx" /* webpackChunkName: "component---src-pages-employer-about-us-tsx" */),
  "component---src-pages-employer-award-recognition-tsx": () => import("./../../../src/pages/employer/award-recognition.tsx" /* webpackChunkName: "component---src-pages-employer-award-recognition-tsx" */),
  "component---src-pages-employer-business-enquiry-tsx": () => import("./../../../src/pages/employer/business-enquiry.tsx" /* webpackChunkName: "component---src-pages-employer-business-enquiry-tsx" */),
  "component---src-pages-employer-case-study-at-a-glance-tsx": () => import("./../../../src/pages/employer/case-study/at-a-glance.tsx" /* webpackChunkName: "component---src-pages-employer-case-study-at-a-glance-tsx" */),
  "component---src-pages-employer-case-study-banner-tsx": () => import("./../../../src/pages/employer/case-study/banner.tsx" /* webpackChunkName: "component---src-pages-employer-case-study-banner-tsx" */),
  "component---src-pages-employer-case-study-case-study-list-tsx": () => import("./../../../src/pages/employer/case-study/caseStudyList.tsx" /* webpackChunkName: "component---src-pages-employer-case-study-case-study-list-tsx" */),
  "component---src-pages-employer-case-study-details-page-tsx": () => import("./../../../src/pages/employer/case-study/details-page.tsx" /* webpackChunkName: "component---src-pages-employer-case-study-details-page-tsx" */),
  "component---src-pages-employer-case-study-id-tsx": () => import("./../../../src/pages/employer/case-study/ID.tsx" /* webpackChunkName: "component---src-pages-employer-case-study-id-tsx" */),
  "component---src-pages-employer-case-study-index-tsx": () => import("./../../../src/pages/employer/case-study/index.tsx" /* webpackChunkName: "component---src-pages-employer-case-study-index-tsx" */),
  "component---src-pages-employer-corporate-social-responsibility-tsx": () => import("./../../../src/pages/employer/corporate-social-responsibility.tsx" /* webpackChunkName: "component---src-pages-employer-corporate-social-responsibility-tsx" */),
  "component---src-pages-employer-index-tsx": () => import("./../../../src/pages/employer/index.tsx" /* webpackChunkName: "component---src-pages-employer-index-tsx" */),
  "component---src-pages-employer-locations-tsx": () => import("./../../../src/pages/employer/locations.tsx" /* webpackChunkName: "component---src-pages-employer-locations-tsx" */),
  "component---src-pages-employer-media-coverage-tsx": () => import("./../../../src/pages/employer/media-coverage.tsx" /* webpackChunkName: "component---src-pages-employer-media-coverage-tsx" */),
  "component---src-pages-employer-media-enquiry-tsx": () => import("./../../../src/pages/employer/media-enquiry.tsx" /* webpackChunkName: "component---src-pages-employer-media-enquiry-tsx" */),
  "component---src-pages-employer-our-values-tsx": () => import("./../../../src/pages/employer/our-values.tsx" /* webpackChunkName: "component---src-pages-employer-our-values-tsx" */),
  "component---src-pages-employer-pf-help-centre-tsx": () => import("./../../../src/pages/employer/PF-help-centre.tsx" /* webpackChunkName: "component---src-pages-employer-pf-help-centre-tsx" */),
  "component---src-pages-employer-press-release-index-tsx": () => import("./../../../src/pages/employer/press-release/index.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-index-tsx" */),
  "component---src-pages-employer-press-release-press-release-1-tsx": () => import("./../../../src/pages/employer/press-release/press-release-1.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-1-tsx" */),
  "component---src-pages-employer-press-release-press-release-10-tsx": () => import("./../../../src/pages/employer/press-release/press-release-10.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-10-tsx" */),
  "component---src-pages-employer-press-release-press-release-11-tsx": () => import("./../../../src/pages/employer/press-release/press-release-11.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-11-tsx" */),
  "component---src-pages-employer-press-release-press-release-12-tsx": () => import("./../../../src/pages/employer/press-release/press-release-12.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-12-tsx" */),
  "component---src-pages-employer-press-release-press-release-2-tsx": () => import("./../../../src/pages/employer/press-release/press-release-2.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-2-tsx" */),
  "component---src-pages-employer-press-release-press-release-3-tsx": () => import("./../../../src/pages/employer/press-release/press-release-3.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-3-tsx" */),
  "component---src-pages-employer-press-release-press-release-5-tsx": () => import("./../../../src/pages/employer/press-release/press-release-5.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-5-tsx" */),
  "component---src-pages-employer-press-release-press-release-6-tsx": () => import("./../../../src/pages/employer/press-release/press-release-6.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-6-tsx" */),
  "component---src-pages-employer-press-release-press-release-7-tsx": () => import("./../../../src/pages/employer/press-release/press-release-7.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-7-tsx" */),
  "component---src-pages-employer-press-release-press-release-8-tsx": () => import("./../../../src/pages/employer/press-release/press-release-8.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-8-tsx" */),
  "component---src-pages-employer-press-release-press-release-9-tsx": () => import("./../../../src/pages/employer/press-release/press-release-9.tsx" /* webpackChunkName: "component---src-pages-employer-press-release-press-release-9-tsx" */),
  "component---src-pages-employer-salary-guide-tsx": () => import("./../../../src/pages/employer/salary-guide.tsx" /* webpackChunkName: "component---src-pages-employer-salary-guide-tsx" */),
  "component---src-pages-employer-solutions-breadcrumb-tsx": () => import("./../../../src/pages/employer/solutions/Breadcrumb.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-breadcrumb-tsx" */),
  "component---src-pages-employer-solutions-enterprise-solutions-tsx": () => import("./../../../src/pages/employer/solutions/enterprise-solutions.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-enterprise-solutions-tsx" */),
  "component---src-pages-employer-solutions-general-staffing-tsx": () => import("./../../../src/pages/employer/solutions/general-staffing.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-general-staffing-tsx" */),
  "component---src-pages-employer-solutions-hire-train-deploy-tsx": () => import("./../../../src/pages/employer/solutions/hire-train-deploy.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-hire-train-deploy-tsx" */),
  "component---src-pages-employer-solutions-hr-advisory-and-consulting-service-tsx": () => import("./../../../src/pages/employer/solutions/hr-advisory-and-consulting-service.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-hr-advisory-and-consulting-service-tsx" */),
  "component---src-pages-employer-solutions-international-engagement-tsx": () => import("./../../../src/pages/employer/solutions/international-engagement.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-international-engagement-tsx" */),
  "component---src-pages-employer-solutions-outsourcing-services-tsx": () => import("./../../../src/pages/employer/solutions/outsourcing-services.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-outsourcing-services-tsx" */),
  "component---src-pages-employer-solutions-payroll-outsourcing-services-tsx": () => import("./../../../src/pages/employer/solutions/payroll-outsourcing-services.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-payroll-outsourcing-services-tsx" */),
  "component---src-pages-employer-solutions-permanent-recruitment-tsx": () => import("./../../../src/pages/employer/solutions/permanent-recruitment.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-permanent-recruitment-tsx" */),
  "component---src-pages-employer-solutions-professional-staffing-tsx": () => import("./../../../src/pages/employer/solutions/professional-staffing.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-professional-staffing-tsx" */),
  "component---src-pages-employer-solutions-rpo-services-tsx": () => import("./../../../src/pages/employer/solutions/rpo-services.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-rpo-services-tsx" */),
  "component---src-pages-employer-solutions-sidebar-tsx": () => import("./../../../src/pages/employer/solutions/sidebar.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-sidebar-tsx" */),
  "component---src-pages-employer-solutions-training-and-skilling-tsx": () => import("./../../../src/pages/employer/solutions/training-and-skilling.tsx" /* webpackChunkName: "component---src-pages-employer-solutions-training-and-skilling-tsx" */),
  "component---src-pages-employer-thank-you-tsx": () => import("./../../../src/pages/employer/thank-you.tsx" /* webpackChunkName: "component---src-pages-employer-thank-you-tsx" */),
  "component---src-pages-employer-videos-tsx": () => import("./../../../src/pages/employer/videos.tsx" /* webpackChunkName: "component---src-pages-employer-videos-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-seeker-details-id-tsx": () => import("./../../../src/pages/job-seeker/details/[ID].tsx" /* webpackChunkName: "component---src-pages-job-seeker-details-id-tsx" */),
  "component---src-pages-job-seeker-thank-you-tsx": () => import("./../../../src/pages/job-seeker/thank-you.tsx" /* webpackChunkName: "component---src-pages-job-seeker-thank-you-tsx" */),
  "component---src-pages-pf-centre-tsx": () => import("./../../../src/pages/pf-centre.tsx" /* webpackChunkName: "component---src-pages-pf-centre-tsx" */),
  "component---src-pages-pf-help-centre-tsx": () => import("./../../../src/pages/pf_help_centre.tsx" /* webpackChunkName: "component---src-pages-pf-help-centre-tsx" */),
  "component---src-pages-pf-self-help-tsx": () => import("./../../../src/pages/PF-self-help.tsx" /* webpackChunkName: "component---src-pages-pf-self-help-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../../../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-refer-a-friend-tsx": () => import("./../../../src/pages/refer-a-friend.tsx" /* webpackChunkName: "component---src-pages-refer-a-friend-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-pages-safe-restart-tsx": () => import("./../../../src/pages/SafeRestart.tsx" /* webpackChunkName: "component---src-pages-safe-restart-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-submit-your-cv-tsx": () => import("./../../../src/pages/submit-your-cv.tsx" /* webpackChunkName: "component---src-pages-submit-your-cv-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-veteran-hiring-tsx": () => import("./../../../src/pages/veteran-hiring.tsx" /* webpackChunkName: "component---src-pages-veteran-hiring-tsx" */),
  "component---src-pages-veteran-submission-form-tsx": () => import("./../../../src/pages/veteran-submission-form.tsx" /* webpackChunkName: "component---src-pages-veteran-submission-form-tsx" */),
  "component---src-pages-virtual-webinar-tsx": () => import("./../../../src/pages/virtual/webinar.tsx" /* webpackChunkName: "component---src-pages-virtual-webinar-tsx" */),
  "component---src-templates-career-advice-temp-tsx": () => import("./../../../src/templates/careerAdviceTemp.tsx" /* webpackChunkName: "component---src-templates-career-advice-temp-tsx" */),
  "component---src-templates-case-study-temp-tsx": () => import("./../../../src/templates/caseStudyTemp.tsx" /* webpackChunkName: "component---src-templates-case-study-temp-tsx" */),
  "component---src-templates-jobs-temp-tsx": () => import("./../../../src/templates/jobsTemp.tsx" /* webpackChunkName: "component---src-templates-jobs-temp-tsx" */)
}

